const Style = ({ attributes, clientId }) => {
	const { background, width, height, alignment } = attributes;

	return <style dangerouslySetInnerHTML={{
		__html: `
		#bChart-${clientId}{
			text-align: ${alignment};
		}
		#bChart-${clientId} .bChart{
			height: ${height};
		}
		#bChart-${clientId} .bChart, #bChart-${clientId} .downloadArea{
			width: ${width};
		}
		#bChart-${clientId} .bChart canvas{
			${background?.styles || 'background-color: #0000;'};
		}
		`.replace(/\s+/g, ' ')
	}} />
}
export default Style;